<!-------------------------------------------------
description:  钱包充扣组件
/**
 *@author xiaosanye 
 *@date 2023/2/14
*/
--------------------------------------------------->
<template>
  <ykc-drawer
    title="钱包充扣"
    :onClose="onCLose"
    :show.sync="showDrawer"
    :before-ensure="submitForm"
    :before-cancel="beforeCancel1">
    <div class="drawer-main-body">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormWRD">
        <ykc-form-item label="充扣对象" prop="orgName">
          <span>{{ row.userAccount || '—' }}</span>
        </ykc-form-item>
        <ykc-form-item label="钱包余额" prop="orgBalance">
          <ykc-input disabled readonly v-model="row.userBalance">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="调整类型" prop="operateType">
          <ykc-radio
            :data="[
              { id: '1', name: '充值' },
              { id: '2', name: '扣款' },
            ]"
            v-model="ruleForm.operateType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item v-if="ruleForm.operateType === '2'" label="可扣回" prop="orgBalance">
          <ykc-input disabled readonly v-model="row.userBalance">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item v-if="ruleForm.operateType === '1'" label="充值金额" prop="amount">
          <ykc-input v-model="ruleForm.amount">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item v-if="ruleForm.operateType === '2'" label="扣款金额" prop="amount">
          <ykc-input v-model="ruleForm.amount">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="备注" prop="remark">
          <ykc-input v-model="ruleForm.remark" type="textarea"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { customerAccount } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'WalletRechargeDeduction',
    components: {},
    props: {
      show1: {
        type: Boolean,
        default: false,
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      // 是否是互联互通账户
      isInterconnected: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showDrawer: false,
        ruleForm: {
          userId: '', // 机构id
          orgName: '', // 机构名称
          userBalance: '', // 钱包余额
          operateType: '1', // 操作类型 1 充 2扣
          amount: '', // 充扣金额
          remark: '', // 备注
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: done => {
                  done(); // 隐藏弹窗
                },
                onConfirm: done => {
                  done(); // 隐藏弹窗
                  this.$emit('wallet-close', false);
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.submitForm();
            },
          },
        ],
      };
    },
    computed: {
      rules() {
        // 扣款验证器
        const validateAmountDeduction = (rule, value, callback) => {
          if (!parseFloat(value) || parseFloat(value) <= 0) {
            callback(new Error('金额不能为0'));
          } else if (parseFloat(this.ruleForm.amount) > parseFloat(this.row.orgBalance)) {
            callback(new Error('扣款金额不能超过可扣回金额!'));
          } else {
            callback();
          }
        };
        // 充值验证器
        const validateAmountRecharge = (rule, value, cb) => {
          if (!parseFloat(value)) {
            cb(new Error('金额不能为0'));
            return;
          }
          cb();
        };
        if (this.ruleForm.operateType === '1') {
          return {
            orgId: [{ required: true, message: '请输入机构ID', trigger: 'blur' }],
            amount: [
              {
                required: true,
                message: regexpObj.regexp.input.amountNumberType.errorTips.empty(),
                trigger: 'blur',
              },
              {
                pattern: regexpObj.regexp.input.amountNumberType.regexp,
                message: regexpObj.regexp.input.amountNumberType.errorTips.error(),
                trigger: 'blur',
              },
              {
                validator: validateAmountRecharge,
                trigger: 'blur',
              },
            ],
            remark: [
              {
                required: true,
                message: regexpObj.regexp.input.textAreaType.errorTips.empty('备注'),
                trigger: 'blur',
              },
              {
                pattern: regexpObj.regexp.input.textAreaType.regexp,
                message: regexpObj.regexp.input.textAreaType.errorTips.error('备注内容'),
                trigger: 'blur',
              },
            ],
            operateType: [{ required: true, message: '请选择操作类型', trigger: 'change' }],
          };
        }
        return {
          orgId: [{ required: true, message: '请输入机构ID', trigger: 'blur' }],
          amount: [
            {
              required: true,
              message: regexpObj.regexp.input.amountNumberType.errorTips.empty(),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              message: regexpObj.regexp.input.amountNumberType.errorTips.error(),
              trigger: 'blur',
            },
            {
              validator: validateAmountDeduction,
              trigger: 'blur',
            },
          ],
          remark: [
            {
              required: true,
              message: regexpObj.regexp.input.textAreaType.errorTips.empty('备注'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('备注内容'),
              trigger: 'blur',
            },
          ],
          operateType: [{ required: true, message: '请选择操作类型', trigger: 'change' }],
        };
      },
    },
    watch: {
      show1(newValue, oldValue) {
        console.log('oldValue:show1', oldValue);
        this.showDrawer = newValue;
      },
      row(newValue, oldValue) {
        console.log('oldValue:row', oldValue);
        this.ruleForm.userId = newValue?.userId;
        this.ruleForm.orgName = newValue?.orgName;
        this.ruleForm.userBalance = newValue?.userBalance;
        this.ruleForm.userAccount = newValue?.userAccount;
        this.doResetSomeField();
      },
    },
    methods: {
      doResetSomeField() {
        this.ruleForm = {
          ...this.ruleForm,
          operateType: '1', // 操作类型
          amount: '', // 充扣金额
          remark: '', // 备注
        };
        this.$nextTick(() => {
          this.$refs?.ruleFormWRD?.clearValidate();
        });
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('wallet-close', false);
        done();
      },
      onCLose() {
        console.log('on close hook');
        this.$emit('wallet-close', false);
      },
      submitForm() {
        this.$refs?.ruleFormWRD.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              const formData = {
                /**
                 * 充值金额
                 */
                amount: this.ruleForm.amount,
                /**
                 * 操作类型 1充值 2扣款
                 */
                operateType: this.ruleForm.operateType,
                /**
                 * 用户id
                 */
                userId: this.ruleForm.userId,
                /**
                 * 备注
                 */
                remark: this.ruleForm.remark,
              };
              // todo  校验规则正确后 请求接口
              customerAccount.interconnectedRechargeOrDeduction(formData).then(res => {
                console.log('inter RD:', res);
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$emit('wallet-close', false);
                this.$emit('refresh-list', true);
              });
            } catch (e) {
              console.error(e);
            }
          } else {
            this.formValid = false;
          }
        });
      },
    },
  };
</script>
